@import '../styles.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pinInputField {
  display: inline-block;

  input {
    outline: 0;
    line-height: 1.4;
    font-size: $font-size-32px;
    background: none;
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    width: 58px;
    height: 70px;
    border-radius: 6px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;

    &:-webkit-autofill {
      border-radius: 0;
      box-shadow: 0 0 0 60px $color-light-gray inset !important;
      background-color: $color-light-gray !important;
      background-clip: content-box !important;
    }
    border: 1px solid $color-sea;
    color: $color-sea-darker;
  }
}

.error {
  input {
    border: 1px solid $color-error-red;
    color: $color-error-red;
  }
}
