@import 'styles.scss';

.LinkInlineIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $color-sea;

  svg {
    @include bounding-box;
  }
}
