@import '../styles.scss';

@mixin link {
  outline: none;
  background: transparent;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: $font-size-14px;
}
@mixin linkLeftRight {
  padding-left: 8px;
  border-radius: 4px;
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #f2f9fa;

  svg {
    @include bounding-box;
  }

  &:focus {
    outline: none;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 0;
  flex: 1;
  border: 1px solid $color-medium-gray-brighter;
  border-radius: 4px;
}

.paginationLink {
  @include link;
  color: $color-medium-gray;
}

.paginationLinkPrevious {
  @include link;
  @include linkLeftRight;
  border-right: 1px solid $color-medium-gray-brighter;
}

.paginationLinkNext {
  @include link;
  @include linkLeftRight;
  border-left: 1px solid $color-medium-gray-brighter;
}

.paginationLinkActive {
  color: $color-sea;
  text-decoration: underline;
  text-underline-position: under;
  button {
    outline: none;
    color: $color-sea;
    background: transparent;
  }
}

.paginationLinkDisabled {
  background-color: $color-brightest;
}
