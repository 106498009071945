// Primary
$color-sea: #359ead;
$color-sea-darker: #1f5e67;
$color-sea-brighter: #aed8de;

// Secondary
$color-salmon: #fd8972;
$color-salmon-darker: #dd6950;
$color-salmon-brighter: #fdaa98;

// Miscellaneous
$color-dark-gray: #242424;
$color-dark-gray-brighter: #333333;
$color-medium-gray: #c1bdba;
$color-medium-gray-brighter: #dedbd9;
$color-light-gray: #f4f4f4;
$color-dove-gray: #666666;
$color-gallery: #eeeeee;
$color-brightest: #ffffff;
$color-light-gray-transparent: rgba(244, 244, 244, 0.8);
$color-dark-gray-transparent: rgba(36, 36, 36, 0.9);

// System Colors
$color-info-yellow: #ffe88a;
$color-success-green: #39ca74;
$color-error-red: #ff4949;

// Font Colors
$color-font-base: #333333;
$color-font-brighter: #999999;

// Font Sizes
$font-size-10px: 10px;
$font-size-12px: 12px;
$font-size-14px: 14px;
$font-size-16px: 16px;
$font-size-20px: 20px;
$font-size-24px: 24px;
$font-size-32px: 32px;

// Font Family
$font-family-base: 'CentraNo2', 'Helvetica';

// Font Weights
$font-weight-book: 400;
$font-weight-medium: 500;
$font-weight-extrabold: 800;

// Line Height
$line-height-20px: 20px;
$line-height-24px: 24px;
$line-height-32px: 32px;

// Spacings
$space-10px: 10px;
$space-20px: 20px;
$space-30px: 30px;
$space-40px: 40px;
$space-50px: 50px;
$space-60px: 60px;
$space-70px: 70px;
$space-80px: 80px;
$space-90px: 90px;
$space-100px: 100px;
$space-120px: 120px;
$space-130px: 130px;
$space-140px: 140px;
$space-150px: 150px;

// Border-Radius
$border-radius: 10px;

// Page & Container
$container-padding: 24px;
$container-max-width: 1220px + ($container-padding * 2);

// Misc
$mobile-menu-height: $space-50px;

// Breakpoints

$breakpoint-xs-min: 0;
$breakpoint-xs-max: 575px;

$breakpoint-sm-min: 576px;
$breakpoint-sm-max: 767px;

$breakpoint-md-min: 768px;
$breakpoint-md-max: 991px;

$breakpoint-lg-min: 992px;
$breakpoint-lg-max: 1199px;

$breakpoint-xl-min: 1200px;

$mediaqueries: (
  mobile-xs-only: '(max-width: #{$breakpoint-xs-max})',
  mobile: '(max-width: #{$breakpoint-sm-max})',
  desktop: '(min-width: #{$breakpoint-md-min})',
  desktop-lg: '(min-width: #{$breakpoint-lg-min})',
  desktop-xl: '(min-width: #{$breakpoint-xl-min})',
);

// Central Media Query Mixin
@mixin respond-to($query) {
  @if map-has-key($mediaqueries, $query) {
    @media #{map-get($mediaqueries, $query)} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$query}`. "
      + "Please make sure it is defined in `$mediaqueries` map.";
  }
}

@mixin clickable-area {
  padding: $space-10px;
  margin: -$space-10px;
  cursor: pointer;
  outline: none;
}

// z-indices
$select-dropdown-z-index: 2000;
$menu-z-index: 5000;
$loading-z-index: 10000;
$overlay-action-z-index: 5001;

$footer-height: $space-70px;

// icons
@mixin bounding-box {
  height: $space-20px;
  fill: $color-sea;
  display: inline-block;
}
