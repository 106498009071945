@import 'styles.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: $color-font-base;
  font-family: $font-family-base;
  font-weight: $font-weight-book;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// reset text styles
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: $font-family-base;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.logo {
  height: 21px;
  fill: $color-sea;

  &--bright {
    color: $color-brightest;
  }
}
