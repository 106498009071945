@import '../styles.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: $loading-z-index;
  background: $color-brightest;
}
