@import 'styles.scss';

.Overlay {
  position: absolute;
  background-color: $color-dark-gray-transparent;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 25%;
  height: 100%;
  z-index: $menu-z-index;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
