@import 'styles.scss';

.StarRating {
  display: flex;
  flex-direction: row;

  &__action {
    cursor: pointer;
    outline: 0;
    list-style: none;
  }

  &__icon {
    @include bounding-box;
    padding-right: $space-10px;

    path {
      fill: none;
    }
  }

  &__icon--fill {
    @include bounding-box;
    padding-right: $space-10px;

    path {
      fill: $color-sea;
    }
  }
}
