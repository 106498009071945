@import 'styles.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-light-gray;
  min-height: 100vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: $container-max-width;
  width: 100%;
  height: 100%;
  padding-top: 5px;
}

.content {
  justify-content: center;
  display: flex;
  flex: 1;
}

.column {
  display: flex;
  width: 100%;
}
