@import 'styles.scss';

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: $color-brightest;
  width: 100%;

  &__wrapper {
    height: 70px;
    background-color: $color-brightest;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $container-max-width;

    a:hover {
      cursor: pointer;
    }
  }

  &__user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    a {
      padding-left: $space-70px;
    }
  }

  svg {
    padding-right: $space-10px;
  }
}
