@import 'styles.scss';

.Error {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__title {
    p {
      color: $color-salmon;
      font-size: $font-size-16px;
      font-weight: $font-weight-extrabold;
      text-align: center;
    }
  }

  &__description {
    p {
      font-size: $font-size-16px;
      font-weight: $font-weight-book;
      text-align: center;
    }
  }

  svg {
    @include bounding-box;
  }

  &__icon {
    svg {
      fill: $color-salmon;
      width: $space-50px;
      height: $space-50px;
      display: inline-block;
    }
  }
}
